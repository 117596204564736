@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=fallback");
@tailwind base;
@tailwind components;
@tailwind utilities;

*,
html,
body {
  font-family: "Poppins", sans-serif !important;
}

button:focus,
select:focus {
  outline: none !important;
  outline-offset: 0px !important;
}

.my-node-enter {
  opacity: 0;
}

.my-node-enter-active {
  opacity: 1;
  transition: opacity 1000ms;
}

.my-node-exit {
  opacity: 1;
}

.my-node-exit-active {
  opacity: 0;
  transition: opacity 1000ms;
}

.my-node2-enter {
  opacity: 0;
}

.my-node2-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}

.my-node2-exit {
  opacity: 1;
}

.my-node2-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

html,
*,
body {
  scroll-behavior: smooth;
}